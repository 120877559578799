/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import List from "react-md/lib/Lists/List";
import ListItem from "react-md/lib/Lists/ListItem";
import SVGIcon from "react-md/lib/SVGIcons/SVGIcon";
import Layout from '../../layout';

const FavoriteIcon = props => (
  <SVGIcon {...props}>
    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
  </SVGIcon>
);

const Who = () => (
  <Layout>
    <Grid stacked>
      <Cell
        align='middle'
        desktopSize={8}
        tabletSize={6}
      >
        <Card>
          <CardTitle title="Our Core Values" />
          <CardText>
            <p>
              <i>
                “Education should no longer be mostly imparting knowledge, but must take a
                new path, seeking the release of human potentials.”
              </i>
            </p>
            <p><i>-- Dr. Maria Montessori</i></p>
            <br />
            <br />
            <p className="md-text-justify">
              Mindful Montessori  is a diverse community of teachers, parents and
              children working together for the education of the child, utilizing the
              methods of Maria Montessori. We serve children ages 4 months -6 years,
              providing a rich learning environment based upon the belief that all
              children can reach their personal and academic potential.
              We believe that the genuine cooperation of parents, students and educators
              creates an effective, highly  successful experience. By engaging with
              and applying our core values, we work to develop responsible and
              caring students who are self-motivated and who respect themselves,
              their peers and their surroundings.
            </p>
            <List>
              <ListItem
                leftAvatar={<FavoriteIcon size={32} primary />}
                primaryText="Education & Lifelong Learning"
                primaryTextClassName="md-font-bold"
              />
              <p className="md-text-justify">
                We believe the Montessori Method is the best educational model to
                achieve our learning goals. As a Montessori school, we value a
                thoughtful and well-prepared learning environment that is both
                purposeful and peaceful. A stimulating and nurturing environment
                should provide students with success and joy to become lifelong learners.
                We expect our teachers and leaders to be lifelong learners who share
                knowledge with and learn from our students, partners, and fellow employees.
              </p>
              <ListItem
                leftAvatar={<FavoriteIcon size={32} primary />}
                primaryText="Community"
                primaryTextClassName="md-font-bold"
              />
              <p className="md-text-justify">
                Each person — student, teacher, leader, Board member, parent, and
                volunteer — who joins Mindful Montessori family belongs to a diverse
                group of students and adults that respects the child and each member
                of our community. Each person is expected to take responsibility for
                himself or herself and the group to contribute to its success.
              </p>
              <ListItem
                leftAvatar={<FavoriteIcon size={32} primary />}
                primaryText="Integrity"
                primaryTextClassName="md-font-bold"
              />
              <p className="md-text-justify">
                We are honest and direct and we make every effort to do the right thing.
                Our community exists to serve children. We continually ask how we may
                better serve each child. The integrity of our decisions is based on
                the best interests of our students.
              </p>
              <ListItem
                leftAvatar={<FavoriteIcon size={32} primary />}
                primaryText="Compassion"
                primaryTextClassName="md-font-bold"
              />
              <p className="md-text-justify">
                We respect our children and one another as members of the community.
                We value the uniqueness of each member of our community and recognize
                every person will have special needs at different times. Understanding
                a situation and responding require compassion and we will give it.
                We are human beings and we treat one another with kindness and dignity.
              </p>
              <ListItem
                leftAvatar={<FavoriteIcon size={32} primary />}
                primaryText="Excellence"
                primaryTextClassName="md-font-bold"
              />
              <p className="md-text-justify">
                We strive to attain high standards of performance in everything we do
                to develop the maximum potential of every child and employee. The
                individual student is empowered to be a self-motivated, self-disciplined,
                and independent learner. Our employees are talented people who are
                giving their best and we work together to create an environment where
                others can do their best as well. We continually seek ways to improve.
              </p>
              <ListItem
                leftAvatar={<FavoriteIcon size={32} primary />}
                primaryText="Mindfulness"
                primaryTextClassName="md-font-bold"
              />
              <p className="md-text-justify">
                We manage our business in a fiscally responsible way while
                upholding our commitment to providing quality Montessori education
                and to supporting our employees’ professional and personal growth.
              </p>
            </List>
          </CardText>
        </Card>
      </Cell>
    </Grid>
  </Layout>
)

export default Who
